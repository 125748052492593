import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate, Link } from 'gatsby';

import { mapStateToProps, daysFromNow } from 'utilities';
import { BookingLayout } from 'containers';
import {
  BookingSummary, BookingAccommAddOn, BookingToolbar,
} from 'components';
import { BookingHeader } from 'layouts';
import {
  GET_BOOKING,
  GET_BOOKING_ACCOM,
  UPDATE_BOOKING,
  GET_ACCOMM_UPGRADES,
} from 'actions/booking';
import {
  GET_DEPARTURE,
} from 'actions/tours';
import { BOOKING_ACCOMM_NIGHTS, PAGES } from 'config';

/**
 * Get the selected accommodation options based on data saved against the booking
 * @param accommodations
 * @returns {Array}
 */
const GET_SELECTED_OPTIONS = (accommodations) => {
  const SELECTED_ACOMMS = [];

  accommodations.forEach((item) => {
    // 3 types  - "before", "after" and "upgrade"
    let customID = 'upgrade';
    if (item.hasOwnProperty('is_before')) { // eslint-disable-line
      customID = item.is_before ? 'before' : 'after';
    }

    // find or create group
    const GROUP_INDEX = SELECTED_ACOMMS.findIndex(group => group.customID === customID);

    let GROUP_SELECTION;
    if (GROUP_INDEX !== -1) {
      GROUP_SELECTION = SELECTED_ACOMMS[GROUP_INDEX];
    } else {
      GROUP_SELECTION = {
        customID,
        id: customID,
        is_before: item.is_before,
        nights: item.nights,
        options: [],
      };
    }

    // Add option to group
    const OPTION_INDEX = GROUP_SELECTION.options.findIndex(option => option.option_id === item.option_id);
    if (OPTION_INDEX !== -1) {
      // increment amount - only one entry per unique option
      GROUP_SELECTION.options[OPTION_INDEX].amount += 1;
    } else {
      GROUP_SELECTION.options.push({
        option_id: item.option_id,
        type: item.type,
        unit_price: item.unit_price,
        amount: 1,
      });
    }

    // Update group ID using options - append option ID
    GROUP_SELECTION.id += `-${item.option_id}`;

    if (GROUP_INDEX !== -1) {
      SELECTED_ACOMMS[GROUP_INDEX] = GROUP_SELECTION;
    } else {
      SELECTED_ACOMMS.push(GROUP_SELECTION);
    }
  });

  return SELECTED_ACOMMS;
};

class BookingsEssentialsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accommOptions: undefined,
      accommodations: undefined,
      formAction: 'save',
      saving: false,
      accommUpdate: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.updateAccomm = this.updateAccomm.bind(this);
    this.updateAccommNights = this.updateAccommNights.bind(this);
  }

  componentDidMount() {
    const { booking } = this.props;
    if (booking.currentBooking) {
      this.getAccommodationOptions();
    }
    const STATE = {};

    if (booking.accommodations) {
      STATE.accommodations = GET_SELECTED_OPTIONS(booking.accommodations);
    }

    if (booking.upgradeAccommodations) {
      // combine with ones added before
      STATE.accommodations = [
        ...STATE.accommodations,
        ...GET_SELECTED_OPTIONS(booking.upgradeAccommodations),
      ];
    }

    if (Object.keys(STATE).length) {
      this.setState({ // eslint-disable-line
        ...STATE,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { booking } = this.props;
    // if we have now got a booking OR accommodations have updated OR upgrade accommodations have updated
    if (
      (!prevProps.booking.currentBooking && booking.currentBooking)
      || (prevProps.booking.accommodations.length !== booking.accommodations.length)
      || (prevProps.booking.upgradeAccommodations.length !== booking.upgradeAccommodations.length)
    ) {
      this.getAccommodationOptions();
    }
    const STATE = {};

    // check if accommodations have been updated
    if (prevProps.booking.accommodations !== booking.accommodations) {
      STATE.accommodations = GET_SELECTED_OPTIONS(booking.accommodations);
    }

    // check if upgrades have been updated
    if (prevProps.booking.upgradeAccommodations !== booking.upgradeAccommodations) {
      // combine with ones added before
      STATE.accommodations = [
        ...STATE.accommodations,
        ...GET_SELECTED_OPTIONS(booking.upgradeAccommodations),
      ];
    }

    if (Object.keys(STATE).length) {
      this.setState({ // eslint-disable-line
        ...STATE,
      });
    }
  }

  /**
   * Get accommodations for this booking
   */
  getAccommodationOptions() {
    const { booking, user } = this.props;

    (async () => {
      const UPGRADES = await GET_ACCOMM_UPGRADES(booking.currentBooking.id, user.session.token, user.session.email);
      const ACCOM_BEFORE = await GET_BOOKING_ACCOM(booking.currentBooking.id, user.session.token, user.session.email, 'before');
      const ACCOMM_AFTER = await GET_BOOKING_ACCOM(booking.currentBooking.id, user.session.token, user.session.email, 'after');
      const DEPARTURE = await GET_DEPARTURE(booking.currentBooking.departure_id);

      const BEFORE = booking.currentBooking.is_reverse ? ACCOMM_AFTER : ACCOM_BEFORE;
      const AFTER = booking.currentBooking.is_reverse ? ACCOM_BEFORE : ACCOMM_AFTER;
      let accommArr = [];

      if (!DEPARTURE.data.upgrade_accommodation_unavailable) {
        accommArr = [{
          customID: 'upgrade',
          is_before: false,
          hasNights: false,
          custom_title: `Accommodation Upgrade - ${UPGRADES.data.title}`,
          options: UPGRADES.data.options,
          picture: UPGRADES.data.picture,
          description_markdown: UPGRADES.data.description_markdown,
        }];
      }

      if (booking.currentBooking.born_to_roam_tour) {
        this.setState({
          accommOptions: accommArr,
        });
      } else {
        this.setState({
          accommOptions: [
            ...accommArr,
            {
              customID: 'before',
              is_before: true,
              hasNights: true,
              custom_title: 'Optional Accommodation Before Tour',
              options: BEFORE.data.options,
              picture: BEFORE.data.picture,
              description_markdown: BEFORE.data.description_markdown,
            },
            {
              customID: 'after',
              is_before: false,
              hasNights: true,
              custom_title: 'Optional Accommodation After Tour',
              options: AFTER.data.options,
              picture: AFTER.data.picture,
              description_markdown: AFTER.data.description_markdown,
            },
          ],
        });
      }
    })();
  }

  /**
   * Handle form submit
   * @param e
   */
  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const { user, booking, dispatch } = this.props;
    const { accommodations, formAction, accommUpdate } = this.state;
    const UPDATED_BOOKING = {
      upgrade_accommodations: [],
      accommodations: [],
    };

    // Prep accomm payload - need an entry for each option (selection contains many options)
    accommodations.forEach((accomm) => {
      accomm.options.forEach((option) => {
        if (!option.option_id) return;

        // split out upgrades
        if (accomm.customID === 'upgrade') {
          let counter = 0;
          while (counter < option.amount) {
            UPDATED_BOOKING.upgrade_accommodations.push({
              booking_id: booking.currentBooking.id,
              option_id: option.option_id,
            });
            counter += 1;
          }
        } else {
          // TODO: back for when an option has no amount?
          for (let i = 1; i <= option.amount; i += 1) {
            UPDATED_BOOKING.accommodations.push({
              is_before: accomm.is_before,
              nights: accomm.nights,
              booking_id: booking.currentBooking.id,
              option_id: option.option_id,
            });
          }
        }
      });
    });

    // Finally update the booking
    this.setState({
      saving: true,
    }, async () => {
      // Destroy all previously selected options
      if (booking.accommodations.length || booking.upgradeAccommodations.length) {
        await UPDATE_BOOKING(booking.currentBooking.id, user.session.token, user.session.email, {
          accommodations: booking.accommodations.map(item => ({
            id: item.id,
            booking_id: item.booking_id,
            option_id: item.option_id,
            _destroy: true,
          })),
          upgrade_accommodations: booking.upgradeAccommodations.map(item => ({
            id: item.id,
            booking_id: item.booking_id,
            option_id: item.option_id,
            _destroy: true,
          })),
        });
      }

      const UPDATE = await UPDATE_BOOKING(booking.currentBooking.id, user.session.token, user.session.email, UPDATED_BOOKING);

      if (!accommUpdate) {
        dispatch({
          type: 'MESSAGES_ADD',
          payload: {
            id: 'update-booking',
            type: UPDATE.success ? 'success' : 'error',
            content: UPDATE.success ? 'Your booking has been updated' : 'Failed to update your booking.',
          },
        });

        setTimeout(() => {
          dispatch({
            type: 'MESSAGES_REMOVE',
            payload: 'update-booking',
          });
        }, 5000);
      }

      if (UPDATE && UPDATE.success) {
        if (formAction === 'next') {
          navigate(`${PAGES.BOOKINGS_REVIEW.PATH}?bookingId=${booking.currentBooking.id}&tour=${booking.currentBooking.tour_slug}`);
        } else {
          // update booking data
          await GET_BOOKING(dispatch, booking.currentBooking.id, user.session.token, user.session.email);
        }
      }

      this.setState({
        saving: false,
        accommUpdate: false,
      });
    });
  }

  /**
   * Update accommodations
   * @param data
   * @param destroy
   */
  updateAccomm(data, destroy) {
    const { accommodations } = this.state;

    let UPDATED = accommodations;

    // Create a new accomm upgrade
    const ACCOM = {
      id: data.id, // this is unique to a "selection"
      customID: data.customID,
      is_before: data.isBefore,
      options: data.options,
      nights: data.numNights, // must have at least one night
    };

    // Remove any other selections for the same add on
    UPDATED = UPDATED.filter(item => item.customID !== ACCOM.customID);

    // if destroy remove from accommodations
    if (!destroy) {
      UPDATED.push(ACCOM);
    }

    this.setState({
      accommodations: UPDATED,
      formAction: 'save',
      accommUpdate: true,
    }, () => this.handleSubmit());
  }

  /**
   * Update nights for all accomms
   * @param data
   * @param nights
   */
  updateAccommNights(data, nights) {
    const { accommodations } = this.state;
    const UPDATED_ACCOMMS = accommodations.map((addOn) => {
      if (addOn.customID === data.customID
        && addOn.is_before === data.isBefore) {
        return {
          ...addOn,
          nights,
        };
      }
      return addOn;
    });
    this.setState({
      accommodations: UPDATED_ACCOMMS,
      formAction: 'save',
      accommUpdate: true,
    }, () => this.handleSubmit());
  }

  /**
   * Set form action to "save"
   */
  handleSave() {
    this.setState({
      formAction: 'save',
    });
    // let form submit...
  }

  /**
   * Set form action to "next"
   */
  handleNext() {
    this.setState({
      formAction: 'next',
    });
    // let form submit...
  }

  render() {
    const { booking } = this.props;
    const {
      accommOptions, accommodations, saving,
    } = this.state;

    let isDisabled = false;
    if (booking.currentBooking) {
      isDisabled = daysFromNow(booking.currentBooking.departure_date) < 60;
    }

    return (
      <BookingLayout page="bookings/essentials">
        <div className={`l-two-col ${saving ? 'essential-is-saving' : ''}`}>
          <BookingHeader
            title="Accommodation"
            subTitle="Select the accommodation for your tour."
          />
          {
            booking.currentBooking && accommOptions
              ? (
                <>
                  <div>
                    {isDisabled && (
                      <div className="c-notice">
                        <h5 className="c-heading c-heading--h5">
                          Please note as you are travelling less than 60 days out, your account is now locked.
                          <br />
                          <br />
                          If you wish to make any upgrades to your accommodation please contact the team at <a className="no-effect-a" href="mailto: info@hakatours.com">info@hakatours.com</a> and 
                          they will see if they can facilitate. Otherwise add-ons will happen on tour.
                        </h5>
                      </div>
                    )}
                    <form action="" onSubmit={this.handleSubmit}>
                      {
                        accommOptions.map(item => (
                          <BookingAccommAddOn
                            key={item.customID}
                            addOn={item}
                            accommodations={accommodations}
                            enableNights={item.hasNights}
                            numNights={BOOKING_ACCOMM_NIGHTS}
                            update={this.updateAccomm}
                            updateNights={this.updateAccommNights}
                            isDisabled={isDisabled}
                            tourCode={booking.currentBooking.tour_code}
                          />
                        ))
                      }
                      <BookingToolbar saving={saving}>
                        {
                          (booking.currentBooking && booking.currentBooking.agree_with_the_conditions)
                            ? (
                              <Link
                                to={`${PAGES.BOOKINGS_REVIEW.PATH}?bookingId=${booking.currentBooking.id}&tour=${booking.currentBooking.tour_slug}`}
                                className="c-button c-button--border u-hide-screen-large-up"
                              >
                                Summary
                              </Link>
                            ) : null
                        }
                        <button onClick={this.handleSave} type="submit" value="Save" className="c-button c-button--border">Save</button>
                        <button onClick={this.handleNext} type="submit" className="c-button c-button--primary c-button--wide">Next</button>
                      </BookingToolbar>
                    </form>
                  </div>
                  <div>
                    <BookingSummary booking={booking.currentBooking} accommodations={booking.accommodations} />
                  </div>
                </>
              )
              : <div className="u-card"><h4 className="c-heading c-heading--h4">Loading...</h4></div>
          }
        </div>
      </BookingLayout>
    );
  }
}

BookingsEssentialsPage.propTypes = {
  user: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(BookingsEssentialsPage);
